import React from 'react';
import { css, injectGlobal } from 'emotion';
import _ from 'lodash';
// import styled, { createGlobalStyle } from 'styled-components';
import 'react-accessible-accordion/dist/minimal-example.css';
import Header from '../components/appComponents/Header';
import { connect } from 'react-redux';
import Rajani from './img/Rajani.png';
import Madhu from './img/Madhu.png';
import Sidd from './img/Sidd.png';
import Jain from './img/Jain.png';
import Kalapala from './img/Kalapala.png';
import Vinod from './img/Vinod.jpg';
import Sedan from './img/Sedan.png';
import Globe from './img/Map.png';
import Partnered from './img/Partnered.png';
import Odometer from './img/Odometer.png';
import ModelCar from './img/Model-Car.png';
import Helmet from 'react-helmet';

/*global tw*/
/*global google*/

injectGlobal(`.row{
  max-width: 82rem;
  @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      //Retina Specific
      max-width: 75rem;
  }
}`)

// export const GlobalStyle = createGlobalStyle`
//   html{
//     font-family: 'Inter', 'Helvetica Nueue', sans-serif;
//     font-size: 16px;
//   }
//   body{
//     ${tw`m-0`};
//     box-sizing: border-box !important;    
//   }
//   p{
//     margin: 0;
//   }
//   div{
//     box-sizing: border-box !important;
//   }
//   `

const styles = {
  listWrapper: css`
        ${tw`w-full bg-white overflow-y-scroll scrolling-touch`};
        border-radius: 16px 16px 0 0;
        height: 91.4511742891vh;
        // transform: translateY(-1vh);
        padding-bottom: 100px;
        box-shadow: 0 0 25px -2px rgba(0,0,0,0.3);
         -webkit-overflow-scrolling: touch;
    `,
  carTitle: css`
        ${tw`my-1`}
        opacity: 0.77;
        font-family: Chromatica-Bold;
        font-size: 25.89px;
        color: #222222;
        letter-spacing: -0.83px;
        padding-top: 0.45rem;

    `,
}

class aboutus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cards: [],
      chipsData: []
    };
  }

  componentDidMount() {
    let cardData = [
      {
        img: Rajani,
        name: "Rajani Kanigiri Kasu",
        title: "CEO & Co-Founder",
        desc: "Rajani has spent close to twenty-years delivering large & complex technology-based projects in global environments. She has an equally strong inclination towards business. Armed with an Executive Masters in Business Administration from the Indian School of Business, she has launched Zyppys to fulfill her entrepreneurial dreams."
      },
      {
        img: Madhu,
        name: "Madhu Raghunayakula",
        title: "COO & Co-Founder",
        desc: "Madhu served 18 years in the US IT industry. Managed end-to-end multi million telecom project with vast experience in business operations & automation process. MBA grad with great insight into Indian market trends. Before Zyppys, launched multiple startups  - online bookstore (kithabay.com), online news portal (andhraheadlines.com)."
      },
      // {
      //   img: Sidd,
      //   name: "Siddhartha Pahwa",
      //   title: "Entrepreneur, Mentor to startups, Ex CEO Meru Cabs.",
      //   desc: "Experienced CEO with a demonstrated history of working in the consumer services industry. Skilled in Negotiation, Business Planning, Operations Management, Strategic Planning, & Forecasting. Strong business development professional with a Chartered Accountant focused in Accountancy from the Institute of Chartered Accountant of India."
      // },
      // {
      //   img: Jain,
      //   name: "Alok Jain",
      //   title: "Managing Director at Trans-Consult Asia, Hong Kong",
      //   desc: "Alok has multi-national experience in building and managing large capital projects. Alok is an Advisor and speaker on SMART mobility, Data Analytics, and Internet-of-Things applications. Alok is a Chartered Member of Chartered Institute of Logistics and Transport. Alok also holds a postgraduate degree in engineering and management."
      // },
      {
        img: Kalapala,
        name: "Srinivasa Kalpala",
        title: "Vice President of Technology and Supplier strategy, Verizon",
        desc: "Srini Kalapala is responsible for developing next-generation network technologies and strategies, identifying and partnering with suppliers to enable Verizon's technology strategy and driving technology architecture efforts across Verizon. Srini holds an MBA from Georgetown University, a Master’s degree in Technology from George Washington University, and a Bachelor of Engineering from BITS Pilani, India."
      },
      {
        img: Vinod,
        name: "Vinod Koppurapu",
        title: "Engineering Director Google",
        desc: "Vinod has significant experience in building and leading high performing engineering teams to deliver technology solutions that enable end-to-end automation, driving process and technology strategy for large scale supply chain, Manufacturing, Data Center fulfillment, and Operations. Prior to Google, he worked at Sun Microsystems and holds a Master's degree in Computer Science."
      }
    ]
    let chipsData = [
      {
        img: Sedan,
        text: 'Cars on platform  ~120K chauffeur driven & ~15K self drive'
      },
      {
        img: Globe,
        text: 'Serviceable cities ~1000 with Chauffeur & 40 self drive'
      },
      {
        img: Partnered,
        text: 'Rental companies partnered - 22'
      },
      {
        img: Odometer,
        text: 'Kilometers travelled 1 Million +'
      },
      {
        img: ModelCar,
        text: 'Car models on platform - 105'
      },
      // {
      //   img: require('./img/Odometer.png'),
      //   text: 'Kilometers travelled 1 Million +'
      // }
    ]
    this.setState({ cards: cardData, chipsData })
  }

  render() {
    return (
      <>
        {/* <GlobalStyle /> */}
        <div className={css`${tw`w-screen max-w-screen max-h-screen overflow-hidden relative`}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)`}>
          <Helmet
            // title='Rental Car App, Car Rental Services, Safe rental cars after lockdown'
             title = 'Zyppys - #1 Outstation Car Rental Services in India. Book Zyppys car rentals for hassle-free journeys for family trips and outings with friends!'
            // title = "Self-Drive Car Rental In India : Outstation Cabs | Rent A Car | Car Hire Online | Outstation Taxi Booking Service | Airport Transfers | Book Hourly Rental Car - ZYPPYS"
            meta={[
              { name: 'description', content: 'Hiring self-drive car is in on your fingertip Zyppys is one of the best car rental services for outstation cabs, cab to airport and more. Zyppys is pocket and environment friendly.' },
              // { name: 'description', content: 'Self Drive Car Rental In India - Outstation Cabs - Airport Transfer Services - Hourly Rental Car : Book best Self-drive car rentals online, hire Self drive cars without driver, Outstation cabs, Outstation taxi, Airport transfer services and Hourly rental cars at affordable prices at ZYPPYS in India.' },
              { name: 'keywords', content: 'Zyypys, Car Rental, car rental near me, car rental app, car rental bangalore, car rental hyderabad, Roadtrip, Outstation Cabs, Road Travel, Chauffeur Driven Cars, Rental Cars, Cheap Rental Cars, Vacation Rentals, Outstation Rentals, Bike Rentals, Aha Taxis, Revv, Zoomcar, Avis, Hertz, Gozo Cabs, Savaari, Taxi Bazaar, Quick Ride, Car Pooling, Car sharing, Travel, Road travel, Airport Taxi, Self Drive Cars, Car Rentals, self drive car hire bangalore,self drive car rental bangalore ,self drive hire bangalore, self drive rental bangalore,self drive car hire hyderabad,self drive car rental hyderabad ,self drive hire hyderabad, self drive rental hyderabad,self drive hire cars ,self drive car rental ,self drive hire , self drive rental , car rental hyderabad, car hire hyderabad,car rental bangalore, car hire bangalore, Bangalore to Coorg car Rental, Bangalore to Mysore car Rental, Bangalore to Ooty car Rental, car rental, car hire, car rental near, outstation cabs, outstation car rental, outstation cabs one way, outstation cabs booking, car booking outstation, Bangalore to Chikmagalur cabs, outstation cabs hyderabad, outstation car rentals hyderabad, outstation cabs one way hyderabad, hyderabad to karimnagar cabs, bangalore to mysore cab, hyderabad to vijayawada cabs, hyderabad to warangal cabs,bangalore to coorg cab,bangalore to ooty cab, bangalore to nandi hills cab, cars, Bangalore to Pondicherry cabs, bangalore to tirupati cab, outstation cabs bangalore, outstation car rentals bangalore, cabs from hyderabad to srisailam, hyderabad to bangalore cab, Bangalore to Kodaikanal cabs, Bangalore to Chennai cabs, Bangalore to Wayanad cabs ' },
              { name: "Viewport", content: "width=device-width" },
              { property: "og:image", content: 'https://zyppysimages.s3.ap-south-1.amazonaws.com/zyplogo.png' },
              { property: "og:image:width", content: "200" },
              { property: "og:image:height", content: "200" },
              { property: "og:type", content: "website" },
              { property: "og:title", content: "Self Drive Cars & Rental Car, Car Rental, Cab on Rent" },
              { property: "og:description", content: "Best car rental service for individual, family, corporate, self-drive cars and customize services for urban & semi-urban cities in India. Zyppys is a platform where people can compare and rent a car." },
              // { property: "og:url", content: "https://zyppys.com/" },
              { property: "og:site_name", content: "Zyppys" },
              { property: "og:locale", content: "en_US" }
            ]}
          />
          <Header />
          <div className={styles.listWrapper}>
            <div
              className={
                css`${tw`w-full flex flex-wrap justify-around lg:justify-between items-end px-2 lg:px-4 mb-6 lg:mx-auto`}; 
                            max-width: 82rem;
                            @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
                                //Retina Specific
                                max-width: 75rem;
                            }`}
            >
              <div className={css`${tw`w-full lg:w-1/2 py-2`}`}>
                <h2 className={styles.carTitle}>About Us</h2>
              </div>
              <p className={css`${tw`text-black text-base  text-sm lg:text-xl `}`}>
                {/* Zyppys is India's first car rental platform. A product with a capability to optimize customer decision to book ideal car instantly. Users can input their required car and itinerary; we match them with the best deal based on price, offers, brand reputation etc. across all the partner brands. Zyppys is One stop shop for all Car rental needs (Self drive & Chauffeur driven). */}
                Experience luxury on the road with our exclusive luxury car rental platform. Explore a curated selection of top-tier vehicles, meticulously maintained to ensure your comfort and style. Whether it's for a special occasion or everyday indulgence, our seamless booking process and personalized service make your journey effortless and unforgettable. Elevate your travel experience with us. 
                </p>

              {this.state.chipsData.map((item, i) =>
                <div className={css`${tw`flex rounded-full items-center shadow-lg mt-10`}`}>
                  <img className={css`${tw`rounded-full h-16 w-16 shadow-md`}`} src={item.img} />
                  <p className={css`${tw`text-center px-4`}`}>{item.text}</p>
                </div>
              )}
            </div>
            {/* border border-grey border-solid */}
            <div className={
              css`${tw`w-full flex flex-wrap justify-between items-end px-2 lg:px-4 mb-6 lg:mx-auto`}; 
                            max-width: 82rem;
                            @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
                                //Retina Specific
                                max-width: 75rem;
                            }`}>
              {
                this.state.cards.map((data, i) => {
                  return (
                    <>
                      {i === 2 && <div className={css`${tw`w-full lg:w-1/2 py-2 ml-4`}`}>
                        <h2 className={styles.carTitle}>Advisors</h2>
                      </div>}
                      {i === 0 &&
                        <div className={css`${tw`w-full lg:w-1/2 py-2 ml-4`}`}>
                          <h2 className={styles.carTitle}>Founders</h2>
                        </div>
                      }
                      <div className={css`${tw`lg:flex inline-block lg:max-w-full max-w-lg rounded-lg overflow-hidden shadow-lg m-4 h-1/2`}`}>
                        <img className={css`${tw`w-full`}`} src={data.img} alt="Beautiful" width='100' height='291' />
                        <div className={css`${tw`px-6 py-4`}`}>
                          <div className={css`${tw`font-bold text-xl mb-2`}`}>{data.name}</div>
                          <p className={css`${tw`text-gray-700 text-base font-semibold`}`}>
                            {data.title}</p>
                          <p className={css`${tw`mt-2 text-gray-700 text-base text-sm lg:text-xl`}`}>
                            {data.desc}</p>
                        </div>
                        <div className={css`${tw`hidden lg:flex px-4 bg-grey`}`}></div>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </div> </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({

})


export default connect(mapStateToProps)(aboutus);